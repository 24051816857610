@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,300,300i,400,400i,500,600,700,700i,800,800i&display=swap');
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {display: block;}
audio,canvas,video {display: inline-block;}
audio:not([controls]) {display: none; height: 0;}
[hidden] {display: none;}
html {font-family: sans-serif; /* 1 */-ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */}
a:focus {outline: none !important;}
a:active,a:hover {outline: 0;}
h1 {font-size: 2em;margin: 0.67em 0;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
mark {background: #ff0;color: #000;}
code,kbd,pre,samp {font-family: monospace, serif;font-size: 1em;}
pre {white-space: pre-wrap;}
q {quotes: "\201C" "\201D" "\2018" "\2019";}
small {font-size: 80%;}
sub,sup {font-size: 75%;line-height: 0;position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
// img {border:0; max-width:100%;}
a{text-decoration:none !important;}
svg:not(:root) {overflow: hidden;}
figure {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}
legend {border: 0; /* 1 */ padding: 0; /* 2 */}
button,input,select,textarea {font-family: inherit; /* 1 */font-size: 100%; /* 2 */margin: 0; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
a{text-decoration:none;}
button,html input[type="button"], /*1*/input[type="reset"],input[type="submit"] {-webkit-appearance: button; /* 2 */ cursor: pointer; /* 3 */}
button[disabled],html input[disabled] {cursor: default;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; /* 1 */ padding: 0; /* 2 */}
input[type="search"] {-webkit-appearance: textfield; /* 1 */-moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */ box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,
input::-moz-focus-inner {border: 0;padding: 0;}
textarea {overflow: auto; /* 1 */ vertical-align: top; /* 2 */}
table {border-collapse: collapse; border-spacing: 0;}
body{
  font-family: $bodyFontFamily;
  margin: 0;
  padding: 0;
  color: $bodyTextColor;
  background:#fff;
  line-height: normal;
}
// .container{
//   max-width:1400px;
// }

.Mlogo{
  display:none !important;
}
.Homeabout{
  margin: 0;
  padding:35px 0 45px 0;
  h1{
    font-size: 20px;
  }
  p{
    font-size: 12px;
    line-height: 22px;
    color: #777;
  }

}
.aboutBh{
  min-height: 450px;
  h1{
    font-size: 24px;
  }
  p{
    font-size: 14px;
    line-height: 26px;
    color: #777;
    letter-spacing: 0.5px;
  }
}
.hedtopBx {
  background-color: #fff;
  padding: 10px 0;
  .searchBx{
    margin-top:12px;
    background:#eee;
    padding:10px;
  }
  .logopc{
    a{
      margin-top: 12px;
      display: inline-block;
    }
  }
  .btn-dark{
    background:#fff;
    color:#616161;
    border: none;
    box-shadow: none;
  }
  .input-group{
    //margin-top: 5px;
  }
  .loginri{
    margin: 0;
    text-align: right;
    font-family: $secondFontFamily;
    ul{
      margin: 0;
      padding:0;
      li{
        display: inline-block;
        font-size:12px;
        padding:4px 10px;
        color: #4d4d4d;
        a{
          color: #4d4d4d;
          &:hover{
            color: #fd7e14;
          }
        }
      }
    }
  }
  .basketmain{
    float: right;
    margin:5px 0;
    background:#eee;
    padding:7px 15px;
    border-radius:4px;
    //text-align: right;
    img{
      float:left;
      margin-right: 8px;
    }
  .basketbx{
    margin:0px 0;
    padding:0;
    //line-height: 0;
    font-size: 12px;
    color: #4d4d4d;
    float:left;
    .basket2{
      font-size: 10px;
      //margin: 0;
      line-height: 18px;
      display: block;
    }
  }
  }
}
.bdsfd{
  background:#f8f8f8;
  margin-top: 25px;
  padding:4px;
  box-shadow:$boxshadow;
}
.header {
  padding: 0px 16px;
  background: #ff9833;
  color: #f1f1f1;

}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.sticky + .content {
  padding-top: 102px;
}

// .navcms_block {
//     float: left;
//     position: relative;
//     padding-top:5px;
//     margin-left: 15px;
// }
// .navcms_block .first-content {
//     float: left;
//     margin-right: 20px;
//     width: auto;
// }
// .navcms_block .first-content .service-right .title,
// .navcms_block .second-content .service-right .title {
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 19px;
//     letter-spacing: 0.5px;
//     color: #666;
// }
// .navcms_block .first-content .service-right .sub-title,
// .navcms_block .second-content .service-right .sub-title {
//     color: #000;
//     font-size: 13px;
//     font-weight: 600;
//     letter-spacing: 0.5px;
//     line-height: 19px;
//     text-transform: capitalize;
// }
// .navcms_block .first-content .icon-left1 {
//     background: rgba(0, 0, 0, 0) url("../images/hicon.png") no-repeat scroll 0 0;
// }
// .navcms_block .first-content:hover .icon-left1 {
//     background: rgba(0, 0, 0, 0) url("../images/hicon.png") no-repeat scroll 0 -55px;
// }
// .cms-icon {
//     float: left;
//     height: 40px;
//     position: relative;
//     width: 40px;
//     margin-right: 12px;
//     transition: all 400ms ease;
//     -webkit-transition: all 400ms ease;
//     -moz-transition: all 400ms ease;
//     -ms-transition: all 400ms ease;
//     -o-transition: all 400ms ease;
//     transition-duration: 0.4s;
//     -webkit-transition-duration: 0.4s;
//     -moz-transition-duration: 0.4s;
//     -ms-transition-duration: 0.4s;
//     -o-transition-duration: 0.4s;
// }
.navcms_block .first-content .service-right,
.navcms_block .second-content .service-right {
    position: relative;
    float: left;
    text-align: left;
    padding-left: 0px;
}
.navcms_block .second-content {
    float: left;
    position: relative;
    width: auto;
}
.navcms_block .second-content .icon-left2
{
    background:url("../images/hicon.png") no-repeat scroll 0 -110px;
}
.navcms_block .second-content:hover .icon-left2
{
    background:url("../images/hicon.png") no-repeat scroll 0 -170px;
}
#tmnav1cmsblock .third-content {
    float: right;
    width: auto;
}
.productBx{
  margin: 0;
  padding: 25px 0;
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
  padding: 0 25px !important;
  color: #fff;
}
.owl-theme .owl-nav{
  margin-top:20px;
}

  h2{
    position:relative;
    border-bottom:#ccc 1px solid;
    font-size:18px;
    color: #222;
    font-family: $headingFont;
    padding-bottom: 8px;
    margin: 25px 0;
    &:after{
      content:"";
     background:#f00;
     position: absolute;
     height:1px;
     bottom: -1px;
     left:0;
     width:90px;
    }
  }
}
.leftBx{
  // background:url(../images/bestselling.jpg);
  border-radius:6px;
  overflow:hidden; 
display:block;
  height:100%;
  .boxbg1{
    background:rgba(233,0,65,0.7);
    padding: 15%;
    display:block;
   height:100%;
    h3{
      color: #fff;
      padding: 25px 0;
    }
    p{
      color: #fff;
    }
  }
  .boxbg2{
    background:rgba(36,87,170,0.7) !important;
  }
}

.bannerAdd{
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
  //padding:5px 15px !important;
  color: #fff;
  border-radius:100%;
  width: 30px;
  height: 30px;
  position:absolute;
  z-index:9;
}
.owl-theme .owl-nav{
  margin-top:20px;
}
.owl-next{
  position:absolute;
  right:0;
   top:45%;
}
.owl-prev{
  position:absolute;
  left:0;
  top:40%;
}
}

#servicecms {
    position: relative;
    clear: both;
    width: 100%;
    margin: 30px 0 40px;
    float: left;
}
#servicecmsblock {
    position: relative;
    width: 100%;
    margin: 0 auto;
    float: left;
    border: 2px solid #f0f0f0;
    padding: 30px 0px;
}
.service-cms-banner-list1, .service-cms-banner-list2, .service-cms-banner-list3, .service-cms-banner-list4 {
    float: left;
    width: 25%;
    position: relative;
    cursor: pointer;
    padding: 0 50px; 
}

#servicecmsblock .service-cms-banner-list1 .service-details::after,
#servicecmsblock .service-cms-banner-list2 .service-details::after,
#servicecmsblock .service-cms-banner-list3 .service-details::after {
    border-left: 2px solid #f0f0f0;
    content: "";
    height: 35px;
    position: absolute;
    right: 0;
    top: 7px;
}
.service1 .service-cms-banner-list1 .service-details .title,
.service1 .service-cms-banner-list2 .service-details .title,
.service1 .service-cms-banner-list3 .service-details .title,
.service1 .service-cms-banner-list4 .service-details .title {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
}
.service1 .service-cms-banner-list1:hover .service-details .title
{
   color: #febd69;
}
.service1 .service-cms-banner-list2:hover .service-details .title
{
    color: #febd69;
}
.service1 .service-cms-banner-list3:hover .service-details .title
{
    color: #febd69;
}
.service1 .service-cms-banner-list4:hover .service-details .title 
{
    color: #febd69;
}
.service1 .service-details .description {
    color: #979797;
    font-weight: 400;
    font-size: 13px;
}
#servicecmsblock .service-cms-banner-list1 .service-icon.smile {
    background: url("../images/services.png") no-repeat scroll center -916px;
    height: 40px;
    width: 40px;
    float: left;
    margin: 0 10px 0 0;
    transition: all 0.3s ease;
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
}
#servicecmsblock .service-cms-banner-list1:hover .service-icon.smile {
    background: url("../images/services.png") no-repeat scroll center -1069px;
}
#servicecmsblock .service-cms-banner-list2 .service-icon.thumbs-up {
    background: url("../images/services.png") no-repeat scroll center -612px;
    height: 40px;
    width: 40px;
    float: left;
    margin: 0 10px 0 0;
    transition: all 0.3s ease;
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
}
#servicecmsblock .service-cms-banner-list2:hover  .service-icon.thumbs-up{
    background: url("../images/services.png") no-repeat scroll center -765px;
}
#servicecmsblock .service-cms-banner-list3 .service-icon.plane {
    background: url("../images/services.png") no-repeat scroll center -303px;
    height: 40px;
    width: 40px;
    float: left;
    margin: 0 10px 0 0;
    transition: all 0.3s ease;
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
}
#servicecmsblock .service-cms-banner-list3:hover .service-icon.plane {
    background: url("../images/services.png") no-repeat scroll center -457px;
}
#servicecmsblock .service-cms-banner-list4 .service-icon.money {
    background: url("../images/services.png") no-repeat scroll center 0px;
    height: 40px;
    width: 40px;
    float: left;
    margin: 0 10px 0 0;
    transition: all 0.3s ease;
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
}
#servicecmsblock .service-cms-banner-list4:hover .service-icon.money {
    background: url("../images/services.png") no-repeat scroll center -153px;
}
// #tmcmsblock .first-content.main-content {
//     cursor: pointer;
//     float: left;
//     position: relative;
//     width: 69.4%;
//     margin-right: 0;
// }
// #tmcmsblock .second-content {
//     cursor: pointer;
//     float: left;
//     position: relative;
//     width: 28.6%;
//     margin: 0 0 0 2%;
// }
// .tab-content .products-section-title{ display:none; }
// #products .product-title,
// .products .product-title,
// .product-accessories .product-title {
//     text-transform: capitalize;
//     margin: 14px 0 12px;
//     display: block;
//     float: left;
// }

.detailBx{
  margin:0;
  min-height: 360px;
  h3{
    border-bottom:#ccc 1px solid;
    font-size:18px;
    color: #000;
    margin:0; 
    padding: 0;
  }
  h5{
    color:#0284BE;
    margin:0; 
    padding: 0;
    font-size:2rem;
    // span{
    //   font-size:12px;
    //   color: #aaa;
    // }
  }
  .inrB{
    margin:0 0 25px 0;
    text-decoration: line-through;
    
  }
  .detUl{
    background:#fff;
    margin:10px 0;
    padding-bottom:10px;
    // border: #eee 1px solid;
    // box-shadow: #ccc 0 5px 10px;
    ul{
      margin:0;
      padding:0px 0px;
      list-style: none; 
      li{
        display:block;
        font-size:12px;
        // line-height:22px;
        padding: 4px 0;
      }
    }
    ul li::before {
      content: "\2058";
      color: #f00;
      font-weight: bold;
      display: inline-block; 
      width: 19px;
      // margin-left: -1em;
      font-size:13px;
    }
  }
  .table-sm th, .table-sm td{
    padding: 0;
  }
}
.detailds{
  padding:15px 0 35px 0;
}





/* This is the moving lens square underneath the mouse pointer. */



// sdfsdf
// cart
.cartBx{

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
  a{
    color: #000;
  }
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: left;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
.cartBott{
  margin-top:15px;
}
}
.md-accordion .card .card-header a:not(.collapsed) .rotate-icon{transform:rotate(180deg);}
.md-accordion .card .fa-angle-down{float:right;}
// Profile
.tab-pane{
    padding-top:20px;

}
.profileBx{
  .aboutBh{
    h4{
      margin:15px 0;
    }
  }
  .img-circle{
    border-radius: 100%;
    margin: auto;
    margin-bottom:15px;
  }
  .custom-file{
    border: #ccc 1px solid;
    cursor: pointer;
    .custom-file-control{
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
      bottom: 0;
      cursor: pointer;
    }
  }
}
// My Orders
.MyOrdersBx{
  margin:10px 0 25px 0;
}
// end

.login{
  .form-control{
    min-height:45px;
    //padding-left: 24px;
  }

}
// :root {
//   --input-padding-x: 1.5rem;
//   --input-padding-y: .75rem;
// }
// .card-signin {
//   border: 0;
//   border-radius: 1rem;
//   box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
// }

// .card-signin .card-title {
//   margin-bottom: 2rem;
//   font-weight: 300;
//   font-size: 1.5rem;
// }

// .card-signin .card-body {
//   padding: 2rem;
// }

// .form-signin {
//   width: 100%;
// }

// .form-signin .btn {
//   font-size: 80%;
//   border-radius: 5rem;
//   letter-spacing: .1rem;
//   font-weight: bold;
//   padding: 1rem;
//   transition: all 0.2s;
// }

// .form-label-group {
//   position: relative;
//   margin-bottom: 1rem;
// }

// .form-label-group input {
//   border-radius: 2rem;
// }

// .form-label-group>input,
// .form-label-group>label {
//   padding: var(--input-padding-y) var(--input-padding-x);
// }

// .form-label-group>label {
//   position: absolute;
//   top: 0;
//   left: 0px;
//   display: block;
//   width: 100%;
//   margin-bottom: 0;
//   /* Override default `<label>` margin */
//   line-height: 1.5;
//   color: #495057;
//   border: 1px solid transparent;
//   border-radius: .25rem;
//   transition: all .1s ease-in-out;
// }

// .form-label-group input::-webkit-input-placeholder {
//   color: transparent;
// }

// .form-label-group input:-ms-input-placeholder {
//   color: transparent;
// }

// .form-label-group input::-ms-input-placeholder {
//   color: transparent;
// }

// .form-label-group input::-moz-placeholder {
//   color: transparent;
// }

// .form-label-group input::placeholder {
//   color: transparent;
// }

// .form-label-group input:not(:placeholder-shown) {
//   padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
//   padding-bottom: calc(var(--input-padding-y) / 3);
// }

// .form-label-group input:not(:placeholder-shown)~label {
//   padding-top: calc(var(--input-padding-y) / 3);
//   padding-bottom: calc(var(--input-padding-y) / 3);
//   font-size: 12px;
//   color: #777;
// }

// .btn-google {
//   color: white;
//   background-color: #ea4335;
// }

// .btn-facebook {
//   color: white;
//   background-color: #3b5998;
// }
/****** Shopping  *****/

.productgrid{
  margin: 0 0 25px 0;
  text-align: center;
  position:relative;
  border:#eee 1px solid;
  padding:8px;
  box-shadow:$boxshadow;
  &:hover{
    border:#000 1px solid;
  }
  .productimage{
    height: 180px;
    position: relative;
    img{
      max-width: 100%; 
      max-height: 100%;
      width: auto !important;
      position:absolute;
      top:0;
      left:0;
      right: 0;
      bottom:0;
      margin: auto;
    }
  }
  .productcontent{
    margin: 0;
    padding-bottom:10px;
    h3{
      color: #000;
      height: 45px;
      line-height: 18px;
      a{
        color: #000;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .price{
      font-size: 12px;
      span{
        display: inline-block;
        padding:0 10px;
      }
    }
  }
  .textD{
    text-decoration:line-through; 
    color:#999;
  }
}


// .product-grid7{
//   font-family:$secondFontFamily;
//   position:relative;
//   z-index:1;

// }
// .product-grid7 .product-image7{
//   border:1px solid rgba(0,0,0,.1);
//   overflow:hidden;
//   perspective:1500px;
//   position:relative;
//   transition:all .3s ease 0s;
//   height:210px;
// }
// .product-grid7 .product-image7 a{
//   display:block;
// }
// .product-grid7 .product-image7 img{
//   max-width:100%;
//   height:auto;
//   position:absolute;
//   margin: auto;
//   bottom: 0;
//   top: 0;
//   left:0;
//   right: 0;
// }
// .product-grid7 .pic-1{
//   opacity:1;
//   transition:all .5s ease-out 0s;
//   background:#fff;
// }
// .product-grid7 .pic-2{
//   opacity:0;
//   transform:rotateY(-90deg);
//   position:absolute;
//   top:0;
//   left:0;
//   transition:all .5s ease-out 0s;

// }
// .product-grid7:hover .pic-2{
//   opacity:1;
//   transform:rotateY(0);
// }
// .product-grid7 .social{
//   padding:0;
//   margin:0;
//   list-style:none;
//   position:absolute;
//   bottom:3px;
//   left:-100%;
//   z-index:1;
//   transition:all .5s ease 0s;
//   background:#fff;
// }
// .product-grid7:hover .social{
//   left:17px;
// }
// .product-grid7 .social li{
//   display:inline-block;
//   background:#fff;
//   font-family: $secondFontFamily;
// }
// .product-grid7 .social li a{
//   color:#fff;
//   background-color:#191f1f;
//   font-size:10px;
//   line-height:35px;
//   text-align:center;
//   height:35px;
//   // width:40px;
//   margin:15px 0;
//   border-radius:4px;
//   display:inline-block;
//   padding: 0 8px;
//   transition:all .5s ease-in-out;
//   text-transform: uppercase;
// }
// .product-grid7 .social li a:hover{
//   color:#fff;
//   background-color:#D50041;
// }
// .product-grid7 .product-new-label{
//   color:#fff;
//   background-color:#333;
//   padding:4px 10px;
//   border-radius:2px;
//   display:block;
//   position:absolute;
//   top:10px;
//   left:10px;
//   font-size:12px;
// }
// .product-grid7 .product-content{
//   text-align:center;
//   padding:20px 0 0; 
//   position:relative;
//   overflow:hidden;
// }
// .product-grid7 .title{
//   font-size:15px;
//   font-weight:600;
//   text-transform:capitalize;
//   margin:0 0 10px;
//   transition:all .3s ease 0s;
// }
// .product-grid7 .title a{
//   color:#333;
// }
// .product-grid7 .title a:hover{
//   color:#000;
// }
// .product-grid7 .rating{
//   color:#FE9901;
//   font-size:12px;
//   padding:0;
//   margin:0 0 10px;
//   list-style:none;
// }
// .product-grid7 .price{
//   color:#333;
//   font-size:20px;
//   font-family:Lora,serif;
//   font-weight:700;
//   margin-bottom:8px;
//   text-align:center;
//   transition:all .3s;
// }
// .product-grid7 .price span{
//   color:#999;
//   font-size:14px;
//   font-weight:700;
//   text-decoration:line-through;
//   margin-left:7px;
//   display:inline-block;
// }





// @media only screen and (max-width:990px){
//   .product-grid7{
//     margin-bottom:30px;
//   }
// }

.footerB{
  margin: 0;
  background:#eee;
  padding: 36px 0;
  h3{
    font-size:14px;
    color: #000;
    padding-bottom:10px;
  }
  p{
    color: #777;
    font-size:13px;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      display:block;
      font-size:13px;
      a{
        color: #818285;
        padding: 4px 0;
        display:inline-block;
        &:hover{
          color: #fe9901;
        }
      }
    }
  }
}
.footertop{
  background:#fff;
  padding: 15px 0;
  // border-top: #bb5977 1px solid;
  color: #000;
  font-size:13px;
  .footr{
    text-align:right;
    a{
      color: #000;
    }
  }
}
.Bnnerb{
  margin: 0;
}
.owl-theme .owl-nav [class*="owl-"]{
  background:#d50041 !important;
  color: #fff;
}
.welcomeBx{
    padding: 45px 0;
    min-height: 380px;
     h1{
    position:relative;
    border-bottom:#ccc 1px solid;
    font-size:24px;
    color: #222;
    font-family: $headingFont;
    padding-bottom: 8px;
    margin: 25px 0;
    &:after{
      content:"";
     background:#f00;
     position: absolute;
     height:1px;
     bottom: -1px;
     left:0;
     width:90px;
    }
  }
  p{
    font-size: 14px;
    line-height: 25px;
    color: #777;
    letter-spacing: 0.5;
  }
}
.pagecont{
    padding: 45px 0;
    min-height: 480px;
     h1{
    position:relative;
    border-bottom:#ccc 1px solid;
    font-size:24px;
    color: #222;
    font-family: $headingFont;
    padding-bottom: 8px;
    margin: 25px 0;
    &:after{
      content:"";
     background:#f00;
     position: absolute;
     height:1px;
     bottom: -1px;
     left:0;
     width:90px;
    }
  }

  }
  // .bzoom_wrap{
  //   padding:45px 0;
  // }
  .bzoom_wrap{
    min-height: 520px;
  }
#pageBx{
  margin: 0;
  .product-grid7{
    margin: 15px 0;
    .product-image7{
      height:290px;
    }
  }
  
  p{
    color: #3b3b3b;
    line-height:26px;
    font-size: 14px;
  }
  }

.contctBx{
  padding-top:45px;
}
.invoBx{
  margin: 0;
  padding:15px;
  border:#ccc 1px solid;
  border-radius:15px;
  h4{
    font-size: 18px;
  }
  p{
    font-size: 13px;
    font-family: arial;
    margin: 0;
    padding:0;
  }
}

#sidebar-wrapper {
  // margin-left: -250px;
  left: 0;
  // width: 250px;
  // background: rgb(0,0,0);
  // position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.5s ease-in 0s;
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;
}

  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sidebar-nav li {
    line-height: 50px;
    text-indent: 20px;
  }

  .sidebar-nav li a {
    color: #000;
    display: block;
    text-decoration: none;
  }

  .sidebar-nav li a:hover {
    color: #f00;
    background: rgba(255,255,255,0.2);
    text-decoration: none;
  }

  .sidebar-nav li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
  }

  .sidebar-nav > .sidebar-brand {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
  }

  .sidebar-nav > .sidebar-brand a {
    color: #999999;
  }

  .sidebar-nav > .sidebar-brand a:hover {
    color: #f00;
    background: none;
  }

  #menu-toggle {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1;
    display:none;
  }
#menu-close{
  display:none;
  position:absolute;
  color: #fff;
  right:10px;
  top:8px;
  i{
    font-size: 29px;
    color: #ffd200;
  }
}
  #sidebar-wrapper.active {
    left: 250px;
    width: 250px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;

  }

  .toggle {
    margin: 5px 5px 0 0;
  }

.sidebx{
  padding:15px 10px 25px 0px;
  h4{
    font-size: 18px;
    color: #000;
    padding:15px 0;
    border-bottom:#f00 1px solid;
    margin-bottom:25px;
  }
}
.colorpick{
  display:inline-block; 
  width:13px; 
  height:13px;
}



.jquery-accordion-menu, .jquery-accordion-menu * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 0
}
.jquery-accordion-menu {
  /*min-width: 260px;*/
  /*float: left;
  position: relative;*/
}
.jquery-accordion-menu .jquery-accordion-menu-footer, .jquery-accordion-menu .jquery-accordion-menu-header {
  width: 100%;
  height: 50px;
  padding-left: 22px;
  float: left;
  line-height: 50px;
  font-weight: 600;
  color: #f0f0f0;
  background: #414956
}
.jquery-accordion-menu ul {
  margin: 0;
  padding: 0;
  list-style: none
}
.jquery-accordion-menu ul li {
  width: 100%;
  display: block;
  float: left;
  position: relative
}
.jquery-accordion-menu ul li a {
  width: 100%;
  padding: 14px 22px;
  float: left;
  text-decoration: none;
  color: #f0f0f0;
  font-size: 13px;
  background: #414956;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  -o-transition: color .2s linear, background .2s linear;
  -moz-transition: color .2s linear, background .2s linear;
  -webkit-transition: color .2s linear, background .2s linear;
  transition: color .2s linear, background .2s linear
}
.jquery-accordion-menu>ul>li.active>a, .jquery-accordion-menu>ul>li:hover>a {
  color: #fff;
  background: #3b424d
}
.jquery-accordion-menu>ul>li>a {
  border-bottom: solid 1px #3b424d
}
.jquery-accordion-menu ul li a i {
  width: 34px;
  float: left;
  line-height: 18px;
  font-size: 16px;
  text-align: left
}
.jquery-accordion-menu .submenu-indicator {
  float: right;
  right: 22px;
  position: absolute;
  line-height: 19px;
  font-size: 20px;
  -o-transition: transform .3s linear;
  -moz-transition: transform .3s linear;
  -webkit-transition: transform .3s linear;
  -ms-transition: transform .3s linear
}
.jquery-accordion-menu ul ul.submenu .submenu-indicator {
  line-height: 16px
}
.jquery-accordion-menu .submenu-indicator-minus>.submenu-indicator {
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}
.jquery-accordion-menu ul ul.submenu, .jquery-accordion-menu ul ul.submenu li ul.submenu {
  width: 100%;
  display: none;
  position: static
}
.jquery-accordion-menu ul ul.submenu li {
  clear: both;
  width: 100%
}
.jquery-accordion-menu ul ul.submenu li a {
  width: 100%;
  float: left;
  font-size: 11px;
  background: #383838;
  border-top: none;
  position: relative;
  border-left: solid 6px transparent;
  -o-transition: border .2s linear;
  -moz-transition: border .2s linear;
  -webkit-transition: border .2s linear;
  transition: border .2s linear
}
.jquery-accordion-menu ul ul.submenu li:hover>a {
  border-left-color: #414956
}
.jquery-accordion-menu ul ul.submenu>li>a {
  padding-left: 30px
}
.jquery-accordion-menu ul ul.submenu>li>ul.submenu>li>a {
  padding-left: 45px
}
.jquery-accordion-menu ul ul.submenu>li>ul.submenu>li>ul.submenu>li>a {
  padding-left: 60px
}
.jquery-accordion-menu ul li .jquery-accordion-menu-label, .jquery-accordion-menu ul ul.submenu li .jquery-accordion-menu-label {
  min-width: 20px;
  padding: 1px 2px 1px 1px;
  position: absolute;
  right: 18px;
  top: 14px;
  font-size: 11px;
  font-weight: 800;
  color: #555;
  text-align: center;
  line-height: 18px;
  background: #f0f0f0;
  border-radius: 100%
}
.jquery-accordion-menu ul ul.submenu li .jquery-accordion-menu-label {
  top: 12px
}




/*.ink{display:block;position:absolute;background:rgba(255,255,255,.3);border-radius:100%;-webkit-transform:scale(0);-moz-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0)}
.animate-ink{-webkit-animation:ripple .5s linear;-moz-animation:ripple .5s linear;-ms-animation:ripple .5s linear;-o-animation:ripple .5s linear;animation:ripple .5s linear}@-webkit-keyframes ripple{100%{opacity:0;-webkit-transform:scale(2.5)}}@-moz-keyframes ripple{100%{opacity:0;-moz-transform:scale(2.5)}}@-o-keyframes ripple{100%{opacity:0;-o-transform:scale(2.5)}}@keyframes ripple{100%{opacity:0;transform:scale(2.5)}}*/
/*.blue.jquery-accordion-menu .jquery-accordion-menu-footer,.blue.jquery-accordion-menu .jquery-accordion-menu-header,.blue.jquery-accordion-menu ul li a{background:#4A89DC}
.blue.jquery-accordion-menu>ul>li.active>a,.blue.jquery-accordion-menu>ul>li:hover>a{background:#3e82da}
.blue.jquery-accordion-menu>ul>li>a{border-bottom-color:#3e82da}
.blue.jquery-accordion-menu ul ul.submenu li:hover>a{border-left-color:#3e82da}
.green.jquery-accordion-menu .jquery-accordion-menu-footer,.green.jquery-accordion-menu .jquery-accordion-menu-header,.green.jquery-accordion-menu ul li a{background:#03A678}
.green.jquery-accordion-menu>ul>li.active>a,.green.jquery-accordion-menu>ul>li:hover>a{background:#049372}.green.jquery-accordion-menu>ul>li>a{border-bottom-color:#049372}
.green.jquery-accordion-menu ul ul.submenu li:hover>a{border-left-color:#049372}.red.jquery-accordion-menu .jquery-accordion-menu-footer,.red.jquery-accordion-menu .jquery-accordion-menu-header,.red.jquery-accordion-menu ul li a{background:#ED5565}.red.jquery-accordion-menu>ul>li.active>a,.red.jquery-accordion-menu>ul>li:hover>a{background:#DA4453}
.red.jquery-accordion-menu>ul>li>a{border-bottom-color:#DA4453}.red.jquery-accordion-menu ul ul.submenu li:hover>a{border-left-color:#DA4453}
.white.jquery-accordion-menu .jquery-accordion-menu-footer,.white.jquery-accordion-menu .jquery-accordion-menu-header,.white.jquery-accordion-menu ul li a{background:#fff;color:#555}
.white.jquery-accordion-menu>ul>li.active>a,.white.jquery-accordion-menu>ul>li:hover>a{background:#f0f0f0}.white.jquery-accordion-menu>ul>li>a{border-bottom-color:#f0f0f0}.white.jquery-accordion-menu ul ul.submenu li:hover>a{border-left-color:#f0f0f0}
.white.jquery-accordion-menu ul ul.submenu li a{color:#f0f0f0}
.white.jquery-accordion-menu>ul>li>a>.ink{background:rgba(0,0,0,.1)}
.black.jquery-accordion-menu .jquery-accordion-menu-footer,.black.jquery-accordion-menu .jquery-accordion-menu-header,.black.jquery-accordion-menu ul li a{background:#292929}.black.jquery-accordion-menu>ul>li.active>a,.black.jquery-accordion-menu>ul>li:hover>a{background:#222}.black.jquery-accordion-menu>ul>li>a{border-bottom-color:#222}.black.jquery-accordion-menu ul ul.submenu li:hover>a{border-left-color:#222}*/

/*jssor slider bullet skin 032 css*/
.jssorb032 {position:absolute;}
.jssorb032 .i {position:absolute;cursor:pointer;}
.jssorb032 .i .b {fill:#fff;fill-opacity:0.7;stroke:#000;stroke-width:1200;stroke-miterlimit:10;stroke-opacity:0.25;}
.jssorb032 .i:hover .b {fill:#000;fill-opacity:.6;stroke:#fff;stroke-opacity:.35;}
.jssorb032 .iav .b {fill:#000;fill-opacity:1;stroke:#fff;stroke-opacity:.35;}
.jssorb032 .i.idn {opacity:.3;}

/*jssor slider arrow skin 051 css*/
.jssora051 {display:block;position:absolute;cursor:pointer;}
.jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
.jssora051:hover {opacity:.8;}
.jssora051.jssora051dn {opacity:.5;}
.jssora051.jssora051ds {opacity:.3;pointer-events:none;}
// banner end

