@media all and (max-width: 1024px ){
  .Mlogo{
    display:block !important;
    background:#D50041 !important;
    padding: 15px 0;
    box-shadow: none !important;
    border:none !important;
  }
.header{
  background:none;
  padding: 4px 0;
}
.hedtopBx{
  padding-top: 65px;
  .input-group{
  margin: 15px 0;
  }
}
.logopc{
  display:none;
}
.leftBx{
  margin: 15px 0;
}
.botp25{
  padding-bottom: 45px;
}
.service-cms-banner-list1{
  width: 100%;
  padding: 0 15px;
}
.service-cms-banner-list2{
  width: 100%;
  margin: 20px 0;
  padding: 0 15px;
}
.service-cms-banner-list3{
  width: 100%;
  margin-bottom: 20px;
  padding: 0 15px;
}
.service-cms-banner-list4{
  width: 100%;
  padding: 0 15px;
}
.product-grid7{
  .product-image7{
    height: 240px;
  }
}
.footertop{
  text-align: center;
  .footr{
    text-align:center;
  }
}
.bzoom_wrap{
  // position: relative !important;
  height: 520px;
}

#menu-close{
  display:block;
}
#sidebar-wrapper {
  margin-left: -250px;
  right:  0;
  top:0;
  width: 250px;
  background: rgb(255,255,255);
  position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.5s ease-in 0s;
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;
}
#menu-toggle {
    top: -60px;
    right:0;
    position: relative;
    z-index: 9;
    float: right;
    width:60px;
    display:block;
    z-index: 1;
  }
#pageBx{
  .product-grid7{
     .product-image7{
      height: 340px;
      img{
        height:100%;
      }
     }
    }
  } 
.sidebx{
  padding:15px 15px;
  
}
}
